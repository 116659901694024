import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { MenuItem, Select } from "@mui/material";

interface ISelectDropdown {
  id: string;
  label?: string;
  selectOptions: any;
  formik?: any;
  onChange?: (value: any) => void;
  value?: any;
  name?: string;
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  optionFullFill?: boolean;
  insideTableHeader?: boolean;
  insideTable?: boolean;
  // in px, used only when insideTableHeader or insideTable is true - set this based on the longest dropdown option
  minWidth?: number;
  // used to bold text before colon, used on offers page when sorting offers
  boldTextBeforeColon?: boolean;
  // if dropdown should be in primary color instead of white, works only if insideTable is set to true
  highlighted?: boolean;
}

const SelectDropdown: React.FC<ISelectDropdown> = ({
  selectOptions,
  onChange,
  value,
  name,
  id,
  formik,
  disabled,
  placeholder,
  optionFullFill,
  insideTableHeader,
  insideTable,
  minWidth,
  boldTextBeforeColon,
  highlighted,
}) => {

  const [open, setOpen] = useState(false); // State to track open status
  const [selectedValue, setSelectedValue] = useState(null);
  const [optionsMinWidth, setOptionsMinWidth] = useState(100);

  const textMeasureRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Helper function to measure text width
    if (selectOptions && (insideTable || insideTableHeader)) {
      const measureTextWidth = (text: string): number => {
        if (!textMeasureRef.current) return 0;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
          context.font = getComputedStyle(textMeasureRef.current).font || "16px Arial";
          return context.measureText(text).width;
        }
        return 0;
      };

      // Calculate the maximum width among all options
      const maxWidth = Math.max(
        ...(selectOptions ?? []).map(option => measureTextWidth(option.label))
      );

      // Set the width of the header to match the widest option or "Actions ˇ"
      const padding = 17;// 16 + border
      // 104 is width of the Actions with the arrow
      // if dropdown is inside of table header, you have to add the arrow width, which is 9px (adding 10 to be safe)
      setOptionsMinWidth(Math.max(Math.ceil(maxWidth + 2 * padding + (insideTableHeader ? 10 : 0)), 104));
    }
  }, [selectOptions]);

  const handleChange = (event: any) => {
    setSelectedValue(event?.target?.value || null);
    if (onChange && event.target.value) {
      onChange(event.target.value);
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let sx: any = {
    color: "var(--darkTextColor)",
    fontSize: "1.5rem",
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--primaryColor)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.1rem solid ${formik?.touched[name] && formik?.errors[name] ? "#ff3d3d" : "var(--darkTextColor)"}`,
      borderRadius: "1rem",
      lineHeight: "2rem",
      marginTop: "0.5rem",
      fontFamily: "var(--fontDMSans)",
      fontWeight: "400"
    },
    '& .MuiSelect-select': {
      padding: optionFullFill && formik?.values[name] ? "9px 0px 0px 0px !important" : "20px 0px 13px 14px",
      fontFamily: "var(--fontDMSans)",
      fontWeight: "400"
    },
    "& .MuiSvgIcon-root": { marginTop: "5px" }
  };
  let MenuProps: any = {
    sx: {
      '& .MuiMenuItem-root': {
        fontSize: "1.5rem",
        color: "var(--darkTextColor)",
        fontFamily: "var(--fontDMSans)",
        fontWeight: "400"

      },
      '& .Mui-selected': {
        backgroundColor: "var(--primaryColor20Solid)",
        fontFamily: "var(--fontDMSans)",
        fontWeight: "400"
      }
    },
  };

  if (insideTableHeader) {
    sx = {
      color: "var(--whiteTextColor)",
      backgroundColor: "var(--primaryVariationColor)",
      // backgroundColor: "red",
      fontSize: "1.5rem",
      // borderRadius: "1.5rem",
      borderTopLeftRadius: "1.5rem",
      borderTopRightRadius: "1.5rem",
      borderBottomLeftRadius: open ? "0" : "1.5rem",
      borderBottomRightRadius: open ? "0" : "1.5rem",
      minWidth: open ? (optionsMinWidth + "px") : undefined,// 120 because of "Merchants"

      height: "3rem",
      marginTop: "0.5rem",
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: "2rem",
        border: "none",
        lineHeight: "2rem",
      },
      '& .MuiSelect-icon': {
        color: "var(--whiteTextColor)",
      },
      '& .MuiSelect-select': {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      "& .MuiSvgIcon-root": { color: "var(--whiteTextColor)" },
    };
    MenuProps = {
      sx: {
        '& .MuiPaper-root': {
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
          borderBottomLeftRadius: "1.5rem",
          borderBottomRightRadius: "1.5rem",
          minWidth: open ? (optionsMinWidth + "px !important") : undefined,
        },
        '& .MuiList-root': {
          backgroundColor: "var(--primaryVariationColor)",
          paddingTop: "0px",
          paddingBottom: "0px",
        },
        '& .MuiMenuItem-root': {
          fontSize: "1.5rem",
          backgroundColor: "var(--primaryVariationColor)",
          color: "var(--whiteTextColor)",
          borderTop: "1px solid var(--whiteTextColor)",
        },
        '& .Mui-selected': {
          backgroundColor: "var(--primaryVariationColor)",
        },
      },
    };
  }

  if (insideTable) {
    sx = {
      color: (open || highlighted) ? "var(--whiteTextColor)" : "var(--primaryTextColor)",
      border: "2px solid var(--primaryColor)",
      fontSize: "1.5rem",
      borderTopLeftRadius: "1.5rem",
      borderTopRightRadius: "1.5rem",
      borderBottomLeftRadius: open ? "0" : "1.5rem",
      borderBottomRightRadius: open ? "0" : "1.5rem",
      minWidth: open ? (optionsMinWidth + "px") : undefined,// 120 because of "Merchants"
      backgroundColor: (open || highlighted) ? "var(--primaryColor)" : "transparent",

      height: "3rem",
      marginTop: "1rem",
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: "2rem",
        border: "none",
        lineHeight: "2rem",
      },
      '& .MuiSelect-icon': {
        color: open ? "var(--whiteTextColor)" : "var(--primaryTextColor)",
      },
      '& .MuiSelect-select': {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      "& .MuiSvgIcon-root": { color: (open || highlighted) ? "var(--whiteTextColor)" : "var(--primaryTextColor)" },
    };
    MenuProps = {
      sx: {
        '& .MuiPaper-root': {
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
          borderBottomLeftRadius: "1.5rem",
          borderBottomRightRadius: "1.5rem",
          minWidth: open ? (optionsMinWidth + "px !important") : undefined,
          border: "1px solid var(--primaryColor)",
        },
        '& .MuiList-root': {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
        '& .MuiMenuItem-root': {
          fontSize: "1.5rem",
          color: "var(--primaryTextColor)",
          borderTop: "1px solid var(--primaryColor)",
          '&:focus': {
            // so first option doesn't apprear as "auto-selected"
            backgroundColor: "transparent",
          },
          '&:hover': {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
        },
        '& .Mui-selected': {
        },
      },
    };
  }

  return <>
    {/* Invisible element to measure text width */}
    <div ref={textMeasureRef} style={{
      visibility: "hidden", whiteSpace: "nowrap", position: "absolute",
      overflow: "hidden", height: "0px",
      fontSize: "1.5rem", fontFamily: "var(--fontDMSans)", fontWeight: "400",
    }}>
      Sample text
    </div>
    <Select
      name={name}
      id={`${id}_selectDropdown`}
      value={value || ""}
      onChange={handleChange}
      displayEmpty
      disabled={disabled}
      sx={sx}
      MenuProps={MenuProps}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <MenuItem value="" disabled style={{ display: "none" }}>
        <em>{placeholder || "Select"}</em>
      </MenuItem>
      {selectOptions?.map((option: any, index: number) => {
        if (boldTextBeforeColon) {
          return <MenuItem key={`${name}_option_${index}`} value={option.value}><span style={{ fontWeight: "bold" }}>{option.label.split(":")[0]}</span>:{option.label.split(":")[1]}</MenuItem>;
        } else if (!boldTextBeforeColon) {
          return <MenuItem key={`${name}_option_${index}`} value={option.value} style={option.style}>{option.label}</MenuItem>;
        }
      })}
    </Select>
  </>;
};

export default SelectDropdown;
