import { IFilter, ISort } from "common/interfaces";
import { csvEscape } from "./helpers";
import _, { isNull } from "lodash";
import moment from "moment";

/**
 * @description Convert an object array to a CSV string.
 * @param {Array<any>}data Table rows.
 * @param {Array<{ label: string, value: string}>} headers Table headers.
 * @returns CSV string.
 */
const convertArrayOfObjectsToCSV = (data: Array<any>, headers: Array<{ label: string, value: string }>): string => {
  if (data == null || !data.length) {
    return null;
  }

  const columnDelimiter: string = ",";
  const lineDelimiter: string = "\n";

  let result: string = "";
  result += headers.map((header: { label: string, value: string }, i) => { return `"${header.label?.replace(/#/g, "Num.") || "none"}"` }).join(columnDelimiter);
  result += lineDelimiter;

  let ctr: number = 0;
  data.forEach((item: any, index: number) => {
    ctr = 0;
    headers.forEach((header: { label: string, value: string }) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      const element = item[header.value];
      if (element == null || element === undefined) {
        result += `""`;
      } else if (element?.props?.linkText) {
        result += `"${csvEscape(element?.props?.linkText)}"`;;
      } else if (typeof element === "object") {
        result += `"${csvEscape(element?.props?.children?.toString())}"`;
      } else {
        result += `"${csvEscape(element?.toString())}"`;
      }
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

/**
 * @description Set this variable to true to increase the console log messages.
 */
window.EnableDebug = false;

/**
 * @description Generate and download CSV file.
 * @param {Array<any>}data Table rows.
 * @param {Array<{ label: string, value: string}>} headers Table headers.
 * @param {string} filename File name. Default: Export.csv.
 */
window.DownloadCSV = function (data: Array<any>, headers: Array<{ label: string, value: string }>, filename: string) {
  let csv: string = convertArrayOfObjectsToCSV(data, headers);
  if (csv == null) {
    return;
  }
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  const content = encodeURI(csv);
  const link = document.createElement('a');
  link.setAttribute('href', content);
  link.setAttribute('download', filename || 'Export.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
   * @description Add querystring values to the original URL.
   * @param filter Filter information.
   * @returns New URL with parameters.
   */
String.prototype.Filter = function (filter: IFilter): string {
  let newUrl: string = this.valueOf();
  _.forOwn(filter, (value, key) => {
    if (value !== undefined && value !== null) {
      let stringValue: string;

      if (typeof value === "string") {
        stringValue = value;
      } else if (typeof value === "number") {
        stringValue = String(value);
      } else if (moment.isMoment(value) || value instanceof Date) {
        stringValue = moment(value).format("YYYY-MM-DD");
      } else {
        stringValue = "";
      }

      newUrl = newUrl.AddQuerystring(key, stringValue);
    }
  });
  return newUrl;
};

/**
 * @description Add querystring values to the original URL.
 * @param sortBy Sorting information.
 * @returns New URL with parameters.
 */
String.prototype.SortBy = function (sortBy: ISort): string {
  if (!sortBy) {
    return this.valueOf();
  }
  return this.valueOf().AddQuerystring("ordering", `${!sortBy.asc ? '-' : ''}${sortBy.key}`);
};
/**
 * @description Add querystring values to the original URL.
 * @param name Querystring name.
 * @param value Querystring value.
 * @returns New URL with parameters.
 */
String.prototype.AddQuerystring = function (name: string, value: string): string {
  if (value === null || value === undefined) {
    return this.valueOf();
  }
  const fullUrl = `${process.env.REACT_APP_DB_URL}${this.valueOf()}`;
  const urlObj = new URL(fullUrl);
  const param = urlObj.searchParams.get(name);
  if (param) {
    urlObj.searchParams.delete(name);
  }
  urlObj.searchParams.append(name, value);
  const newUrl = urlObj.pathname.substring(1) + urlObj.search;
  return newUrl;
};
/**
* @description Compares 2 strings. Case insensitive and trimmed.
* @param text String to compare.
* @returns true if equal.
*/
String.prototype.Equals = function (text: string): boolean {
  if (this.valueOf() === null && text === null) {
    return true;
  }
  if (this.valueOf() === undefined && text === undefined) {
    return true;
  }
  return this.valueOf()?.trim()?.toLowerCase() === text?.trim()?.toLowerCase();
}
/**
 * @description Capitalize text.
 * @returns New capitalized text.
 */
String.prototype.Capitalize = function (): string {
  if (!this.valueOf()) {
    return this.valueOf();
  }
  const parts: Array<string> = this.valueOf().split(' ');
  let newText: Array<string> = [];
  _.forEach(parts, part => {
    if (part.length === 1) {
      newText.push(part.toUpperCase());
    } else if (part.length > 1) {
      newText.push(`${part.substring(0, 1).toUpperCase()}${part.substring(1).toLowerCase()}`);
    }
  });
  return newText.join(' ');
}